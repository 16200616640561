import styled from '@emotion/styled'
import Image from '../Image'

const PortfolioItemImage = styled(Image)`
  width: 100%;
  height: 100%;
  object-fit: cover;
`

export default PortfolioItemImage
