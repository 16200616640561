import * as React from 'react'

const ScaleDownIcon = () => {
  return (
    <svg viewBox="0 0 32 31" width="100%" height="100%" color="currentColor" transition=".5s">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.7406 17.6704C20.4037 17.3335 20.3557 16.8078 20.5897 16.3927C21.538 14.7102 22.0552 12.7563 21.9959 10.6851C21.8219 4.61251 16.7581 -0.169351 10.6854 0.00459928C4.61281 0.178488 -0.169049 5.24233 0.00484024 11.315C0.17879 17.3876 5.24263 22.1695 11.3153 21.9955C13.2759 21.9394 15.1021 21.3735 16.6703 20.4285C17.0886 20.1764 17.6306 20.2173 17.976 20.5627L28.0877 30.6744C28.4782 31.0649 29.1114 31.0649 29.5019 30.6744L30.9161 29.2602C31.3067 28.8697 31.3067 28.2365 30.9161 27.846L20.7406 17.6704ZM11.2523 19.7964C6.39418 19.9356 2.3431 16.1101 2.20394 11.252C2.06484 6.39388 5.89034 2.3428 10.7484 2.2037C15.6065 2.06454 19.6576 5.89004 19.7968 10.7481C19.9359 15.6062 16.1104 19.6574 11.2523 19.7964Z"
        fill="currentColor"
      />
      <path
        d="M4.00024 11C4.00024 10.4477 4.44796 10 5.00024 10H17.0002C17.5525 10 18.0002 10.4477 18.0002 11C18.0002 11.5523 17.5525 12 17.0002 12H5.00024C4.44796 12 4.00024 11.5523 4.00024 11Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default ScaleDownIcon
