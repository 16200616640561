import * as React from 'react'

const MailIcon = () => {
  return (
    <svg viewBox="0 0 25 19" width="100%" height="100%" color="currentColor" transition=".5s">
      <path
        fill="currentColor"
        d="M2.29796 18.9568H22.702C23.9691 18.9568 25 17.9258 25 16.6586V2.29806C25 1.03085 23.9691 0 22.702 0H2.29796C1.03085 0 0 1.03095 0 2.29806V16.6586C0 17.9258 1.03085 18.9568 2.29796 18.9568ZM1.49659 2.29806C1.49659 1.85547 1.85587 1.49659 2.29796 1.49659H22.702C23.1443 1.49659 23.5034 1.85547 23.5034 2.29806V16.6586C23.5034 17.1013 23.1442 17.4602 22.702 17.4602H2.29796C1.85577 17.4602 1.49659 17.1013 1.49659 16.6586V2.29806ZM4.46362 16.4018H2.96704V2.61843L12.3131 8.6861C12.4231 8.75743 12.5768 8.75753 12.6871 8.6859L22.0331 2.61843V16.4018H20.5365V5.37435L13.5018 9.94133C13.2033 10.135 12.8569 10.2373 12.5 10.2373C12.1431 10.2373 11.7967 10.135 11.4984 9.94143L4.46362 5.37435V16.4018Z"
      />
    </svg>
  )
}

export default MailIcon
